import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium, mgrid) {
        this.breakpointMedium = breakpointMedium;
        this.anchorScroll = false;
    }
    init() {
        this.scrollToAnchorInit();
        this.scrollDown();
    }
    scrollDown() {
        if (document.querySelectorAll('.arrow-down').length > 0) {
            document.querySelector('.arrow-down').addEventListener('click', function () {
                gsap.to(window, { duration: 0.6, scrollTo: window.innerHeight, ease: "power2.out" });
            });
        }
    }
    scrollToAnchorInit() {
        var self = this;
        //if (document.body.classList.contains('home')) {
        Array.from(document.querySelectorAll('a[href*="#"]')).forEach(function (anchor) {
            if (anchor.getAttribute('href') != '#contactlayer') {
                anchor.addEventListener('click', function (e) {
                    self.anchorScroll = true;
                    self.scrollToAnchor(e, anchor.getAttribute('href'));
                    e.preventDefault();
                });
            } else {
                anchor.addEventListener('click', function (e) {
                    self.openContact();
                });
            }
        });
        //}
        if (window.location.hash) {
            //window.addEventListener('load', this.scrollToAnchor.bind(this));
        }
    }
    scrollToAnchor(evt, anchor) {
        if (anchor == undefined) {
            anchor = window.location.hash;
        }
        var self = this;
        let a = anchor.split('#')[1];
        if (a != null && a != undefined) {
            let p = document.getElementById(a).getBoundingClientRect().top + window.pageYOffset - 30;
            gsap.to(window, { duration: 1.4, scrollTo: p, ease: "power3.out", onComplete: self.resetAnchorSCroll, onCompleteParams: [self] });
        }
    }
    resetAnchorSCroll(self) {
        self.anchorScroll = false;
    }
}
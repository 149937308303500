import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import ShuffleItems from './ShuffleItems.js';


export class Slider {
    constructor() {
        this.speed = 3000;
        this.fadetime = 800;
        this.shuffle = null;
    }

    init() {
        const self = this;

        if (document.querySelectorAll('.hero-slider').length > 0) {
            this.shuffle = new ShuffleItems('.hero-slider .swiper-wrapper');
        }

        if (document.querySelectorAll('.swiper-container').length > 0) {
            Array.from(document.querySelectorAll('.swiper-container figure')).forEach(function (img) {
                if (img.parentNode.nodeName.toLowerCase() == 'a') {
                    img.parentNode.classList.add('swiper-slide');
                    img.parentNode.classList.add('swiper-lazy');
                } else {
                    img.classList.add('swiper-slide');
                    img.classList.add('swiper-lazy');
                }
            });
            Array.from(document.querySelectorAll('.swiper-container')).forEach(function (s) {
                if (s.hasAttribute('data-speed')) {
                    self.speed = s.getAttribute('data-speed');
                    if (self.speed < 1000) {
                        self.fadetime = 1;
                    } else {
                        self.fadetime = 800;
                    }
                }
                var slideshow = new Swiper(s, {
                    modules: [Autoplay, EffectFade],
                    slidesPerView: 1,
                    loop: true,
                    speed: self.fadetime,
                    grabCursor: true,
                    slidesPerView: 1,
                    effect: "fade",
                    fadeEffect: { crossFade: true },
                    autoplay: {
                        delay: self.speed,
                        //disableOnInteraction: true
                    },
                });
            });
            if (document.querySelectorAll('.hero-slider').length > 0) {
                document.querySelector('.hero-slider').classList.add('show');
            }
        }
    }
}
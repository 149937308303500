import { BaseFunctions } from "./js/basefunctions.js";
import { Slider } from "./js/slider.js";

const breakpointMedium = 768;
const breakpointLarge = 1100;

document.addEventListener("DOMContentLoaded", function (event) {
    const basefunctions = new BaseFunctions(breakpointMedium);
    basefunctions.init();

    const slider = new Slider();
    slider.init();  
});